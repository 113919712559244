import React from 'react';
import { Switch, Route } from 'react-router-dom';


import MateriiSecretariat from './MateriiSecretariat';
import MateriiCD from './MateriiCD';
import Login from './Login';
import Home from './Home';
import Teste from './Teste';
import UploadPreview from './UploadPreview';
import Statistici from './Statistici';
import AdminConfigUpload from './AdminConfigUpload';
import MateriiEvaluare from './MateriiEvaluare';
import MateriiEvaluare2 from './MateriiEvaluare2';

const Main = () => {
  return (
    <Switch> {/* The Switch decides which component to show based on the current URL.*/}
      <Route exact path='/' component={Login}></Route>
      <Route exact path='/home' component={Home}></Route>
      <Route exact path='/materii/lista-materii-secretariat' component={MateriiSecretariat}></Route>
      <Route exact path='/materii/lista-materii-cd' component={MateriiCD}></Route>
      <Route exact path='/materii/lista-materii-evaluare' component={MateriiEvaluare}></Route>
      <Route exact path='/materii/lista-materii-evaluare2' component={MateriiEvaluare2}></Route>
      <Route exact path='/teste' component={Teste}></Route>
      <Route exact path='/upload/preview' component={UploadPreview}></Route>
      <Route exact path='/statistici/upload' component={Statistici}></Route>
      <Route exact path='/admin/config/upload' component={AdminConfigUpload}></Route>
    </Switch>
  );
}

export default Main;