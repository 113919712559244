import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import xlsxLogo from '../xlsx.svg';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Checkbox from '@material-ui/core/Checkbox';

import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import Slide from '@material-ui/core/Slide';

import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import PublishIcon from '@material-ui/icons/Publish';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DeleteIcon from '@material-ui/icons/Delete';
import HistoryIcon from '@material-ui/icons/History';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SwapVerticalCircleIcon from '@material-ui/icons/SwapVerticalCircle';

import { orange } from '@material-ui/core/colors';

const CADRU_CURS = 0;
const CADRU_ACTIVITATE = 1;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  table: {
    minWidth: 650,
  },
  button: {
    margin: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  xlsxLogo: {
    marginRight: theme.spacing(1),
    height: 16,
    width: 16,
  },
  dialogDescription: {
    margin: theme.spacing(1),
    textAlign: "justify",
  },
  logoutButton: {
    position: "absolute",
    right: 16,
    top: 116,
  },
  notaCell: {
    padding: 0,
  },
  notaText: {
    marginRight: theme.spacing(2),
    textAlign: "right",
  },
  textAlignRight: {
    textAlign: "right",
  },
  square: {
    height: theme.spacing(2),
    width: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  }
}));

const ColorFab = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(orange[800]),
    backgroundColor: orange[500],
    '&:hover': {
      backgroundColor: orange[700],
    },
  },
}))(Fab);

var attempts = 40;
var downloadTimer;

const bgColors = {
  "": "#DDDDDD",
  "m": "#FFA500A0",
  "a": "#00FF00A0",
  "r": "#FF0000A0"
};

function expireCookie( cName ) {
  document.cookie = cName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

function getCookie( name ) {
  var parts = document.cookie.split(name + "=");
  if (parts.length == 2) return parts.pop().split(";").shift();
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function MateriiEvaluare() {
  const [materiiJson, setMateriiJson] = useState([]);
  const [materiiFiltrateJson, setMateriiFiltrateJson] = useState([]);
  const [studentiJson, setStudentiJson] = useState([]);
  const [aniStudiu, setAniStudiu] = useState([]);
  const [anFilter, setAnFilter] = useState("");
  const [semestruFilter, setSemestruFilter] = useState("");
  const [textFilter, setTextFilter] = useState("");
  const [profilCurent, setProfilCurent] = useState({});
  const [openPrevizualizareStudenti, setOpenPrevizualizareStudenti] = useState(false);
  const [cadreJson, setCadreJson] = useState([]);
  const [cadreFiltrateJson, setCadreFiltrateJson] = useState([]);
  const [departamente, setDepartamente] = useState([]);
  const [departamentFilter, setDepartamentFilter] = useState("");

  const [openErrorInfo, setOpenErrorInfo] = useState(false);
  const [errorInfoDescription, setErrorInfoDescription] = useState("");

  const [isLoading, setLoading] = useState(true);




  const classes = useStyles();

  useEffect(() => {
    fetch("/get/materiiEvaluare2")
      .then((response) => response.json())
      .then(mJson => {
        getAniStudiu(mJson);
        setMateriiJson(mJson);
        setMateriiFiltrateJson(mJson);
        
      });
    
    fetch("/get/profilCurent")
    .then((response) => response.json())
    .then(pJson => setProfilCurent(pJson));

    fetch("/get/cadre")
    .then((response) => response.json())
    .then(cJson => {
      getDepartamente(cJson);
      setCadreJson(cJson);
      const el = document.querySelector(".loader-container");
        if (el) {
          el.innerHTML = "";
          setLoading(!isLoading);
        }
    });
    
  }, []);

  useEffect(() => {
    filterMaterii();
  }, [anFilter, semestruFilter, textFilter]);

  useEffect(() => {
    filterCadre();
  }, [cadreJson]);

  useEffect(() => {
    filterCadre();
  }, [departamentFilter]);

  useEffect(() => {
    if(errorInfoDescription != "") {
      setOpenErrorInfo(true);
    }
  }, [errorInfoDescription]);

  useEffect(() => {
    if(openErrorInfo == false) {
      setErrorInfoDescription("");
    }
  }, [openErrorInfo]);
    
  // var search1 = '\\.';
  // var search2 = "\\*";  

  // var replacer1 = new RegExp(search1, 'g');
  // var replacer2 = new RegExp(search2, 'g');

  function getAniStudiu(mJson) {
    var aniS = [];

    mJson.forEach((materie) => {
      if(materie.AN != null && !aniS.includes(materie.AN))
        aniS.push(materie.AN);
    });

    setAniStudiu(aniS);
  }

  function getDepartamente(cJson) {
    var dep = [];

    cJson.forEach((cadru) => {
      if(cadru.departament != null && !dep.includes(cadru.departament))
        dep.push(cadru.departament);
    });

    setDepartamente(dep);
  }

  function filterMaterii() {
    var materiiFiltrate = [];

    materiiJson.forEach((materie) => {
      if(('' + materie.AN).includes(anFilter) && ('' + materie.semestru).includes(semestruFilter) && (materie.COD_SPEC.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(textFilter.toUpperCase()) || (materie.DEN_DISC + ((materie.VALABILITATE != "2023-2024" && materie.VALABILITATE != "-") ? ` (${materie.VALABILITATE})` : "")).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(textFilter.toUpperCase()) || materie.PREFIX.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(textFilter.toUpperCase()) ))
        materiiFiltrate.push(materie);
    });

    setMateriiFiltrateJson(materiiFiltrate);
  }


  function handleAssignCadru(oldCadre, newCadre, tip_cadru, DISCIPLINA) {
    var type;
    var cadru;

    // console.log(oldCadre);
    if(oldCadre === undefined || !oldCadre.hasOwnProperty(tip_cadru)) {
      var den_disciplina = DISCIPLINA.split("__")[2];
      var prefix_disciplina = DISCIPLINA.split("__")[0];
      var cod_disciplina = DISCIPLINA.split("__")[1];

      materiiJson.forEach((materie) => {
        if(materie.DEN_DISC == den_disciplina && materie.PREFIX == prefix_disciplina && materie.COD_SPEC == cod_disciplina) {
          if(oldCadre === undefined) {
            materie.CADRU_DIDACTIC = {};
            materie.CADRU_DIDACTIC[tip_cadru] = [];
          }
          else {
            materie.CADRU_DIDACTIC[tip_cadru] = [];
          }
          
          oldCadre = materie.CADRU_DIDACTIC;
        }
      });
    }
    
    oldCadre = oldCadre[tip_cadru];

    if(oldCadre.length < newCadre.length) {
      type = 1; //add
      cadru = newCadre[newCadre.length - 1];
      oldCadre.push(newCadre[newCadre.length - 1])
    }
    else {
      type = 0; //remove
      var found = false; //found within the first (length - 1) elements, meaning the an element other than the last one was removed
      for(var i = 0; i < oldCadre.length - 1; i++) {
        if(oldCadre[i].email != newCadre[i].email) {
          cadru = oldCadre[i];
          found = true;
          oldCadre.splice(i, 1);
        }
      }
      
      if(found == false) {
        cadru = oldCadre[oldCadre.length - 1];
        oldCadre.splice(oldCadre.length - 1, 1);
      }
    }

    cadreJson.forEach(cadruFull => {
      if(cadruFull.email == cadru.email) {
        cadru = cadruFull;
      }
    })

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/materii/asignEvaluare", true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    
    xhr.onload = function() {}

    xhr.send(JSON.stringify({
      DISCIPLINA: DISCIPLINA,
      CADRU: cadru.nume + "__" + cadru.email + "__" + cadru.departament + "__" + cadru.titlu + "__" + tip_cadru,
      TYPE: type
    }));
  }

  function handleCheckedChange(DISCIPLINA) {
    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/materii/editIsEvaluated", true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    
    xhr.onload = function() {
      var den_disciplina = DISCIPLINA.split("__")[2];
      var prefix_disciplina = DISCIPLINA.split("__")[0];
      var cod_disciplina = DISCIPLINA.split("__")[1];
      var isEvaluated = DISCIPLINA.split("__")[3];

      materiiJson.forEach((materie) => {
        if(materie.DEN_DISC == den_disciplina && materie.PREFIX == prefix_disciplina && materie.COD_SPEC == cod_disciplina) {
          materie.isEvaluated = parseInt(isEvaluated);
        }
      });
      filterMaterii();
    }

    xhr.send(JSON.stringify({
      DISCIPLINA: DISCIPLINA,
    }));
  }

  function handleClickPrevizualizareStudenti(DEN_DISC, PREFIX, COD_SPEC) {
    const el = document.querySelector(".loader-container");
    el.innerHTML = "<div class=\"loader\"></div>";
    setLoading(!isLoading);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/get/note", true);

    xhr.onload = function() {
      setStudentiJson(JSON.parse(xhr.response));
      setOpenPrevizualizareStudenti(true);
      el.innerHTML = "";
      setLoading(!isLoading);
    }

    const formData = new FormData();
    formData.append('DEN_DISC', DEN_DISC);
    formData.append('PREFIX', PREFIX);
    formData.append('COD_SPEC', COD_SPEC);

    xhr.send(formData);
  }

  function filterDefaultCadre(cadre) {
    var foundCadre = [];

    for(var i = 0; i < cadreFiltrateJson.length; i++) {
      for(var j = 0; j < cadre.length; j++) {
        if(cadreFiltrateJson[i].email === cadre[j].email) {
          foundCadre.push(cadreFiltrateJson[i]);
        }
      }
    }

    return foundCadre;
  }


  function filterCadre() {
    var cadreFiltrate = [];

    cadreJson.forEach((cadruDidactic) => {
      if(('' + cadruDidactic.departament).includes(departamentFilter))
        cadreFiltrate.push({"email": cadruDidactic.email, "nume": cadruDidactic.nume});
    });

    setCadreFiltrateJson(cadreFiltrate);
  }

  const handleCloseErrorInfo = () => {
    setOpenErrorInfo(false);
  };

  const handleClosePrevizualizareStudenti = () => {
    setOpenPrevizualizareStudenti(false);
    setStudentiJson([]);
  };

  if(cadreFiltrateJson.length === 0) {
    return <></>;
  }

  //const fileName = materie.AN + "_" + materie.DEN_DISC + "_" + materie.PREFIX.replace(replacer1, '') + "_" + materie.COD_SPEC.replace(replacer1, '').replace(replacer2, '') + ".xlsx";
  return (
    <div className="MateriiEvaluare">
      <Fab 
        size="large"
        className={classes.logoutButton}
        onClick={(e) => {
          e.preventDefault();
          window.location.href='/autentificare/logout';
        }}
      >
        <ExitToAppIcon />
      </Fab>
      <h2 className={classes.margin}>Listă discipline la specializarea "{profilCurent.denumire_profil != undefined ? (profilCurent.denumire_profil + " - " + profilCurent.cod_profil + "_" + profilCurent.cod_specializare) : ""}"</h2>
      <br></br>

      <Table fixedHeader={false} style={{ width: "auto", tableLayout: "auto" }}>
        <TableHead>
          <TableRow>
            <TableCell>Se evaluează</TableCell>
            <TableCell>An studiu</TableCell>
            <TableCell>Disciplina</TableCell>
            <TableCell>Prefix_Cod</TableCell>
            <TableCell>
              <div className={classes.margin}>
                Cadru didactic curs
              </div>
            </TableCell>
            <TableCell>
              <div className={classes.margin}>
                Cadru didactic activitate
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {materiiFiltrateJson.map((materie, index) => ( //materie.PREFIX.replace(replacer1, '') + "_" + materie.COD_SPEC.replace(replacer1, '').replace(replacer2, '')
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={materie.isEvaluated}
                  color="primary"
                  onChange={e =>handleCheckedChange(materie.PREFIX + "__" + materie.COD_SPEC + "__" + materie.DEN_DISC + "__" + (e.target.checked ? 1 : 0))}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </TableCell>
              <TableCell><center>{materie.AN}</center></TableCell>
              <TableCell>
                <Tooltip title="Previzualizare studenți">
                  <Fab size="small" color="primary" aria-label="previzualizare-studenti" className={classes.margin} onClick={() => handleClickPrevizualizareStudenti(materie.DEN_DISC, materie.PREFIX, materie.COD_SPEC)}>
                    <VisibilityIcon />
                  </Fab>
                </Tooltip>
                {materie.DEN_DISC + ((materie.VALABILITATE != "2023-2024" && materie.VALABILITATE != "-") ? ` (${materie.VALABILITATE})` : "")}
              </TableCell>
              <TableCell>
                {(materie.AN != null && !materie.PREFIX.includes(profilCurent.cod_profil + "." + profilCurent.cod_specializare)) ?
                  (<div style={{color: 'orange'}}>{materie.PREFIX + "_" + materie.COD_SPEC}</div>)
                  :
                  (materie.PREFIX + "_" + materie.COD_SPEC)
                }
              </TableCell>
              <TableCell>
                {materie.AN == null ? 
                  ""
                  :
                  (materie.hasOwnProperty("CADRU_DIDACTIC") && materie.CADRU_DIDACTIC.hasOwnProperty("0") ? (
                    <Autocomplete
                      multiple
                      id="cadru-curs-select"
                      defaultValue={filterDefaultCadre(materie.CADRU_DIDACTIC["0"])}
                      onChange={(event, cadruDidactic) => handleAssignCadru(materie.CADRU_DIDACTIC, cadruDidactic, CADRU_CURS, materie.PREFIX + "__" + materie.COD_SPEC + "__" + materie.DEN_DISC)}
                      options={cadreFiltrateJson}
                      getOptionLabel={(cadruDidactic) => (cadruDidactic.nume + " - " + cadruDidactic.email)}
                      style={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} label="Cadrul Didactic curs" />}
                    />)
                    : (
                    <Autocomplete
                      multiple
                      id="cadru-curs-select"
                      onChange={(event, cadruDidactic) => handleAssignCadru(materie.CADRU_DIDACTIC, cadruDidactic, CADRU_CURS, materie.PREFIX + "__" + materie.COD_SPEC + "__" + materie.DEN_DISC)}
                      options={cadreFiltrateJson}
                      getOptionLabel={(cadruDidactic) => (cadruDidactic.nume + " - " + cadruDidactic.email)}
                      style={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} label="Cadrul Didactic curs" />}
                    />)
                  )
                }
              </TableCell>
              <TableCell>
                {materie.AN == null ? 
                  ""
                  :
                  (materie.hasOwnProperty("CADRU_DIDACTIC") && materie.CADRU_DIDACTIC.hasOwnProperty("1") ? (
                    <Autocomplete
                      multiple
                      id="cadru-curs-select"
                      defaultValue={filterDefaultCadre(materie.CADRU_DIDACTIC["1"])}
                      onChange={(event, cadruDidactic) => handleAssignCadru(materie.CADRU_DIDACTIC, cadruDidactic, CADRU_ACTIVITATE, materie.PREFIX + "__" + materie.COD_SPEC + "__" + materie.DEN_DISC)}
                      options={cadreFiltrateJson}
                      getOptionLabel={(cadruDidactic) => (cadruDidactic.nume + " - " + cadruDidactic.email)}
                      style={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} label="Cadrul Didactic activitate" />}
                    />)
                    : (
                    <Autocomplete
                      multiple
                      id="cadru-curs-select"
                      onChange={(event, cadruDidactic) => handleAssignCadru(materie.CADRU_DIDACTIC, cadruDidactic, CADRU_ACTIVITATE, materie.PREFIX + "__" + materie.COD_SPEC + "__" + materie.DEN_DISC)}
                      options={cadreFiltrateJson}
                      getOptionLabel={(cadruDidactic) => (cadruDidactic.nume + " - " + cadruDidactic.email)}
                      style={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} label="Cadrul Didactic activitate" />}
                    />)
                  )
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog 
        TransitionComponent={Transition}
        fullWidth="true"
        maxWidth="lg"
        aria-labelledby="previzualizare-studenti-title" 
        aria-describedby="previzualizare-studenti-description"
        open={openPrevizualizareStudenti}
        onClose={handleClosePrevizualizareStudenti}
      >
        <DialogTitle id="previzualizare-studenti-title">{"Studenți"}</DialogTitle>
        <Table stickyHeader fixedHeader={false} style={{ width: "auto", tableLayout: "auto" }}>
          <TableHead>
            <TableRow>
              <TableCell><b>Nr. crt.</b></TableCell>
              <TableCell><b>Marca</b></TableCell>
              <TableCell><b>Grupa</b></TableCell>
              <TableCell><b>Numele și prenumele</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studentiJson.map((student, index) => 
              <TableRow>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{student.MARCA}</TableCell>
                <TableCell>{student.GR_ADM}</TableCell>
                <TableCell>{student.NUME}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <DialogActions>
          <Button onClick={handleClosePrevizualizareStudenti} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog 
        TransitionComponent={Transition}
        onClose={handleCloseErrorInfo}
        aria-labelledby="error-info-title"
        aria-describedby="error-info-description"
        open={openErrorInfo}>
        <DialogTitle id="error-info-title">{"Eroare"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="error-info-description">
            {errorInfoDescription}
          </DialogContentText>
        </DialogContent>
      </Dialog>  
    </div>
  ); 
}

export default MateriiEvaluare;
